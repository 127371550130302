import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"
import { ButtonLink } from "components/buttons"

const StyledContainer = styled(Container)`
  max-width: 1150px;
  overflow: hidden;
  
      h1 {
      padding-bottom: 1rem;
    }

	p {
			width: 90%;
			margin: 0 auto 1rem;
	}
	
      h2 {
      font-size: 28px;
      padding: 3rem 0 0.8rem;
    }

	   h2 + p {
			line-height: 1.6em;
			width: 95%;
			margin: 0 auto;

		  ${breakpoint.xs`
			width: 77%;
		  `}

		  ${breakpoint.small`
			width: 65%;
		  `}

		  ${breakpoint.medium`
			width: 75%;
		  `}

		  ${breakpoint.large`
			width: 72%;
		  `}

		  ${breakpoint.extraLarge`
			width: 67%;
		  `}

		}

`

const StyledSideBySide = styled.section`
	overflow: hidden;
	margin: 80px 0 0;
	text-align: center;

	${breakpoint.medium`
		margin: 110px;
	`}

	.feature {
		padding-top: 30px;
	}
	
	.styledFeature {
		max-width: 450px;
		position: relative;
		display: flex;
		flex-wrap: wrap;
		margin: 0 auto 10px;
		column-gap: 0px;
		text-align: center;
	
	  ${breakpoint.medium`
		max-width: 100%;
		flex-wrap: wrap;
	  `}

	.order1 {
		order: 1;
		height: auto;
		align-self: center;
		
	  ${breakpoint.medium`
			order: 1;
	  `}
	}

	.order2 {
		order: 3;
	  ${breakpoint.medium`
			order: 2;
	  `}
	}

	.order3 {
		order: 2;
	  ${breakpoint.medium`
			order: 3;
	  `}
	}

	.order4 {
		order: 4;
	}

  .feature__image {
    width: 100%;
    height: auto;
    margin: 0px auto 20px;

    ${breakpoint.medium`
		width: 50%;
		max-width: 500px;
    `}
  }

  .feature__content {
    width: 100%;
    margin: 2px auto 40px;

    ${breakpoint.medium`
		width: 45%;
		margin: 5px auto;
    `}

    h3 {
      font-weight: 600;
      font-size: 23px;
      margin-bottom: 1rem;
      
       ${breakpoint.medium`
        font-size: 28px;
      `}  
     }

    p {
		font-size: 17px;
		line-height: 1.5em;
		width: 75%;
		text-align: center;
		margin: 0 auto 2em;

       ${breakpoint.medium`
        font-size: 19px;
        line-height: 1.6em;
      `}  
    }
    
		p > a {
		color: ${colors.purpleDark};
		
		&:hover {
			color: ${colors.orange};
		}
	 }

  }
  
  ${ButtonLink} {
  	min-width: 220px;
  	width: auto;
	}
  	
`

const SideBySide = () => {

	return (
		<StyledSideBySide>
			<StyledContainer>
				<h1 data-aos="fade-up" data-aos-once="false">The Only Instrument-Free,<br />Rapid PCR Test</h1>
				<p data-aos="fade-up" data-aos-once="false" data-aos-delay="100">
					Our rapid PCR tests provide accurate and reliable results for healthcare providers all across the United States.
					We are a medical diagnostics company with portable PCR testing technology that may be implemented in both
					clinical and mobile laboratory settings.
				</p>
				<p data-aos="fade-up" data-aos-once="false" data-aos-delay="150">
					Visby Medical strives to make access to infectious disease testing as easy and as accessible as possible.
				</p>

				<div className="feature" data-aos="fade-up" data-aos-once="false" data-aos-delay="200">
					<div className="styledFeature">
						<StaticImage
							src="../../../assets/images/homepage/respiratory-health-test.png"
							width={800}
							alt="Visby Medical Respiratory Health Test"
							className="feature__image order2"
						/>
						<div className="feature__content order4">
							<ButtonLink backgroundcolor="orange" to="/respiratory-health-test/" data-aos="fade-up" data-aos-once="false">Respiratory Health Test Device</ButtonLink>
						</div>
						<StaticImage
							src="../../../assets/images/homepage/sexual-health-test.png"
							width={800}
							alt="Visby Medical Sexual Health Test"
							className="feature__image order1"
						/>
						<div className="feature__content order3">
							<ButtonLink backgroundcolor="orange" to="/sexual-health-test/" data-aos="fade-up" data-aos-once="false">Sexual Health Test Device</ButtonLink>
						</div>
					</div>
				</div>

			</StyledContainer>
		</StyledSideBySide>
	)
}

export default SideBySide
